/* eslint-disable no-eval */
import React, { useState, useEffect } from "react";
import { useApiGet } from "../../../api/apiCall";
import { getRecentNoticessUrl } from "../../../api/urls";
import {
	GlobalMenu,
	// BlockAccent,
	LandingCard,
	LandingCardDetails,
	MessageBox,
	PageLoader
} from "../../../ui_elements";
import { LandingModal } from "./containers";
import DOMPurify from "dompurify";
import styles from "./styles.module.css";
import { ArrowUp } from "../../../assets/svgs";
import { APPLICATION_ID, APPLICATION_MODE } from "../../../utils/constants";
import LandingAccordion from "../../../ui_elements/landingAccordion";
import NotificationBanner from "./banner";
// import { getCoursesForRegistrationUrl } from "./../../../api/urlCategories/CourseRegistration";

const Home = () => {
	const [message, setMessage] = useState(true);
	const [modal, setModal] = useState(false);
	const { data, isFetched, isLoading, error } = useApiGet(
		getRecentNoticessUrl(),
		{
			keepPreviousData: true
		}
	);
	const date = new Date();

	useEffect(() => {
		if (isFetched) {
			setModal(true);
		}
	}, [isFetched]);

	const [infoState, setInfoState] = useState(0);

	const landingPageMenu = {
		undergraduate: {
			id: 0,
			hash_id: "undergraduate",
			title: "Undergraduate",
			descriptionTitle: "Post UTME Application",
			descriptionBody:
				"Candidates who chose the Federal University of Technology, Owerri as their First Choice University in the 2024 Unified Tertiary Matriculation Examination (UTME) and Direct Entry (DE) are hereby invited to the Screening Exercise. The following conditions apply:",
			listItem: [
				"UTME candidates must have scored a minimum of 150 in their UTME",
				"Direct Entry candidates must be holders of OND/HND/First Degree and A'Level in relevant disciplines and subjects.",
				"Those who did not choose FUTO as their First Choice University but scored a minimum of 150 can also apply on the condition that they MUST visit the JAMB website for a change of Institution to FUTO as their First Choice University."
			],
			links: [
				{
					linkTitle: "Generate Invoice",
					route: () => handleCurrentLink(applicationForms?.postUme)
				},
				{
					linkTitle: "Application Form",
					route: "/putme_login"
				},
				{
					linkTitle: "Print 2024-2025 PUTME Result",
					route: "/putme_login",
				}
			]
		},
		jupeb: {
			id: 1,
			hash_id: "jupeb",
			title: "JUPEB",
			descriptionTitle: "JUPEB",
			descriptionBody:
				"Application into the JUPEB programme is currently ongoing. Please see the advert for more important information.",
			links: [
				{
					linkTitle: "Generate Invoice",
					route: () => handleCurrentLink(applicationForms?.jupeb)
				},
				{
					linkTitle: "Application Form",
					route: "/jupeb_login"
				}
			]
		},
		directEntry: {
			id: 2,
			hash_id: "direct_entry",
			title: "Direct Entry",
			descriptionTitle: "Direct Entry",
			descriptionBody:
				"You can check the links below for information concerning Direct Entry application and admission status",
			links: [
				{
					linkTitle: "Generate Invoice",
					route: () =>
						handleCurrentLink(applicationForms?.direct_entry)
				},
				{
					linkTitle: "Application Form",
					route: "/direct_entry_login"
				}
			]
		},
		supplementary: {
			id: 3,
			hash_id: "supplementary",
			title: "Supplementary",
			descriptionTitle: "Supplementary",
			descriptionBody:
				"Application into the Supplementary programme is currently ongoing. Please see the advert for more important information.",
			links: [
				{
					linkTitle: "Generate Invoice",
					route: () =>
						handleCurrentLink(applicationForms?.supplementary)
				},
				{
					linkTitle: "Application Form",
					route: "/supplementary_login"
				},
				{
					linkTitle: "Print 2024-2025 Supplementary Result",
					route: "/supplementary_login",
				}
			]
		},
		// supplementaryPutme: {
		// 	id: 4,
		// 	hash_id: "supplementary_putme",
		// 	title: "Supplementary (PUTME)",
		// 	descriptionTitle: "Supplementary (PUTME)",
		// 	descriptionBody:
		// 		"Application into the Supplementary (PUTME) programme is currently ongoing. Please see the advert for more important information.",
		// 	links: [
		// 		{
		// 			linkTitle: "Generate Invoice",
		// 			route: () =>
		// 				handleCurrentLink(applicationForms?.supplementaryPutme)
		// 		},
		// 		{
		// 			linkTitle: "Application Form",
		// 			route: "/supplementary_putme_login"
		// 		}
		// 	]
		// },
		postgraduate: {
			id: 5,
			hash_id: "postgraduate",
			title: "Postgraduate",
			descriptionTitle: "Postgraduate",
			descriptionBody:
				"The Postgraduate School, Federal University of Technology, Owerri (FUTO) invites applications from suitably qualified candidates for consideration for admission into the underlisted Diploma, Masters and Doctorate Degree programmes for the 2023/2024 session under Regular, Centre for Continuing Education (Weekend), Institute of Women/Gender and Development Studies (IWoGDS), Institute of Erosion Studies (IES) and Public Procurement Research Centre (PPRC). Any interested candidate should pay a non-refundable fee of Eleven Thousand, Three Hundred Naira (N11,300.00) only through the University website and as well fill the application form online.",
			links: [
				{
					linkTitle: "Generate Invoice",
					route: () => handleCurrentLink(applicationForms?.pg)
				},
				{
					linkTitle: "Application Form",
					route: "/pg_login"
				},
				{
					linkTitle: "Track Application",
					route: "/pg_reprint_login"
				}
			]
		},
		preDegree: {
			id: 6,
			hash_id: "pre_degree",
			title: "Pre-Degree",
			descriptionTitle: "Pre-Degree",
			descriptionBody:
				"You can check the links below for information concerning pre-degree application and admission status",
			links: [
				{
					linkTitle: "Generate Invoice",
					route: () => handleCurrentLink(applicationForms?.pre_degree)
				},
				{
					linkTitle: "Application Form",
					route: "/pre_degree_login"
				}
			]
		},
		cce: {
			id: 7,
			hash_id: "cce",
			title: "CCE",
			descriptionTitle: "CCE",
			descriptionBody:
				"You can check the links below for information concerning cce application and admission status",
			links: [
				{
					linkTitle: "Generate Invoice",
					route: () => handleCurrentLink(applicationForms?.cce)
				},
				{
					linkTitle: "Application Form",
					route: "/cce_login"
				}
			]
		}
	};

	const handleCurrentLink = (linkObject) => {
		if (
			linkObject?.applicationMode === APPLICATION_MODE.UTME ||
			linkObject?.applicationMode === APPLICATION_MODE.DIRECT_ENTRY
		) {
			return {
				pathname: `/generate_jamb_students_invoice`,
				state: { application: linkObject }
			};
		} else if (linkObject?.applicationMode === APPLICATION_MODE.PG) {
			return {
				pathname: `/generate_application_invoice`,
				state: { application: linkObject }
			};
		} else if (linkObject?.applicationMode === APPLICATION_MODE.CCE) {
			return {
				pathname: `/generate_cce_invoice`,
				state: { application: linkObject }
			};
		} else {
			console.log("Not a valid application mode");
		}
	};

	const applicationForms = {
		postUme: {
			applicationMode: APPLICATION_MODE.UTME,
			id: APPLICATION_ID.UTME,
			name: "Post UTME Application"
		},
		jupeb: {
			applicationMode: APPLICATION_MODE.JUPEB,
			id: APPLICATION_ID.JUPEB,
			name: "JUPEB"
		},
		direct_entry: {
			applicationMode: APPLICATION_MODE.DIRECT_ENTRY,
			id: APPLICATION_ID.DIRECT_ENTRY,
			name: "Direct Entry Application"
		},
		pg: {
			applicationMode: APPLICATION_MODE.PG,
			id: APPLICATION_ID.PG,
			name: "Post Graduate Application"
		},
		supplementary: {
			applicationMode: APPLICATION_MODE.SUPPLEMENTARY,
			id: APPLICATION_ID.SUPPLEMENTARY,
			name: "Supplementary Application"
		},
		pre_degree: {
			applicationMode: APPLICATION_MODE.PRE_DEGREE,
			id: APPLICATION_ID.PRE_DEGREE,
			name: "Pre Degree Application"
		},
		cce: {
			applicationMode: APPLICATION_MODE.CCE,
			id: APPLICATION_ID.CCE,
			name: "CCE Application"
		}
	};

	if (isLoading) return <PageLoader />;
	if (error)
		return "An error has occurred: " + error?.response?.data?.message;

	return (
		<div className={`${styles.body}`}>
			{!data?.data || data?.data.length === 0 ? null : (
				<LandingModal
					data={data?.data}
					isOpen={modal}
					closeModal={() => setModal(false)}
				/>
			)}
			{!data?.data ||
			data?.data?.length === 0 ||
			message === false ? null : (
				<MessageBox
					openModal={() => setModal(true)}
					closeMessage={setMessage}
					title={data?.data[0]?.title}
					message={
						<div
							className={`d-flex align-items-baseline ${styles.message_body}`}
						>
							<div
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(
										data?.data[0]?.description.slice(0, 70)
									)
								}}
							/>
							<span>....</span>
						</div>
					}
				/>
			)}
			<GlobalMenu isLanding={"unAuthenticated"} />
			<NotificationBanner />
			<main>
				<section
					className={`d-flex align-items-center justify-content-center ${styles.title} ${styles.container}`}
				>
					<h1 className={`text-center`}>
						Welcome to Federal University of Technology Portal{" "}
					</h1>
					<p>
						Experience the best education with our diverse programs
						and ample learning resources. Explore more below.
					</p>
					{/* {window.innerWidth > 768 ? (
						<>
							<BlockAccent size={92} top={60} left={18} />
							<BlockAccent size={48} top={68} left={16.5} />
						</>
					) : (
						<>
							<BlockAccent size={50} top={78} left={12} />
							<BlockAccent size={30} top={86} left={8.5} />
						</>
					)}
					{window.innerWidth > 768 ? (
						<BlockAccent size={32} top={1.5} left={29} />
					) : (
						<BlockAccent size={28} top={17.5} left={25} />
					)}

					{window.innerWidth > 768 ? (
						<>
							<BlockAccent size={92} top={-5} left={80} />
							<BlockAccent size={48} top={3} left={84} />
							<BlockAccent size={32} top={80} left={90} />
						</>
					) : (
						<>
							<BlockAccent size={34} top={9} left={80} />
							<BlockAccent size={20} top={14} left={86} />
						</>
					)} */}
				</section>
				<section className={`${styles.secondSection}`}>
					<h2
						className={
							window.innerWidth > 768
								? `text-center`
								: `text-left`
						}
					>
						Our Programmes
					</h2>
					{window.innerWidth > 768 ? (
						<div
							className={`d-flex ${
								window.innerWidth > 768
									? `justify-content-center`
									: `justify-content-start`
							} ${styles.cardContainer}`}
						>
							<div>
								{Object.entries(landingPageMenu).map(
									([cardTitle, cardValues]) => (
										<div
											className={`${styles.infoSection}`}
										>
											<LandingCard
												id={cardValues.id}
												infoState={infoState}
												setInfoState={setInfoState}
												route={`#${cardValues.hash_id}`}
											>
												{cardValues.title}
											</LandingCard>
										</div>
									)
								)}
							</div>
							<div>
								<LandingCardDetails
									info={Object.values(landingPageMenu)}
								/>
							</div>
						</div>
					) : (
						<LandingAccordion
							data={landingPageMenu}
							infoState={infoState}
							setInfoState={setInfoState}
						/>
					)}
				</section>
			</main>

			<footer
				className={`d-flex flex-column align-items-center justify-content-center ${styles.home_footer}`}
			>
				<p className={`${styles.call_centre_number}`}>
					Need help? <span>-</span> <span>08139833300</span>
				</p>
				<p>
					{`Copyright©️ ${date.getFullYear()} All rights reserved by Federal University of
					Technology Owerri. Powered by Tenece`}
				</p>
			</footer>
			<div
				className={`${styles.fab} d-flex align-items-center justify-content-center`}
				onClick={() =>
					window.scrollTo({
						top: 0,
						behavior: "smooth"
					})
				}
			>
				<ArrowUp />
			</div>
		</div>
	);
};

export default Home;
